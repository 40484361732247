import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-black small text-center text-white-50">
      <div className="footer-container">
        <p>hello@fluidnationyoga.com.au  </p>
      </div>
      <div className="footer-container">
        <p>© {new Date().getFullYear()}{' '}<a href="https://fluidnationyoga.com.au/">Fluid Nation Yoga</a></p>        
      </div>
    </footer>
  );
}
